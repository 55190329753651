/* TODO: Can probably get rid of the chrono-code and chrono-pre classes when the theme is switched over to custom */
code.chrono-code {
  /* Override highlighter color */
  [data-highlighted-chars] {
    @apply dark:bg-[var(--highlight-dark)];
  }

  span {
    @apply bg-[var(--shiki-light-bg)] text-[var(--shiki-light)];
    @apply dark:bg-[var(--shiki-dark-bg)] dark:text-[var(--shiki-dark)];
  }

  font-feature-settings:
    'rlig' 1,
    'calt' 1,
    'ss01' 1;

  &[data-line-numbers] > .line {
    @apply pl-2;
    &::before {
      counter-increment: line;
      content: counter(line);
      @apply float-left h-full min-w-[2.6rem] pr-4 text-right text-gray-500;
    }
  }

  .line {
    &.highlighted {
      @apply bg-green-600/10 text-green-600/50 shadow-[2px_0_currentColor_inset];
    }
    .highlighted {
      @apply rounded-sm shadow-[0_0_0_2px_rgba(0,0,0,.3)];
      @apply bg-green-800/10 shadow-green-800/10;
      @apply dark:bg-green-300/10 dark:shadow-green-300/10;
    }
  }
}

pre.chrono-pre {
  /* content-visibility: auto; */
  contain: paint;
  code {
    @apply grid min-w-full rounded-none border-none !bg-transparent !p-0 text-sm leading-5 text-current dark:!bg-transparent;
    .line {
      @apply px-4;
    }
  }

  &:not([data-theme]) {
    @apply px-4;
  }

  html[data-chrono-word-wrap] & {
    word-break: break-word;
    @apply whitespace-pre-wrap md:whitespace-pre;
    .line {
      @apply inline-block;
    }
  }
}

/* See: https://nextra.site/docs/guide/syntax-highlighting */
:root {
  --highlight-dark: #6d4009; /* orange 800 */

  --shiki-color-text: oklch(37.53% 0 0);
  --shiki-color-background: transparent;
  --shiki-token-constant: oklch(56.45% 0.163 253.27);
  --shiki-token-string: oklch(54.64% 0.144 147.32);
  --shiki-token-comment: oklch(73.8% 0 0);
  --shiki-token-keyword: oklch(56.8% 0.2 26.41);
  --shiki-token-parameter: oklch(77.03% 0.174 64.05);
  --shiki-token-function: oklch(50.15% 0.188 294.99);
  --shiki-token-string-expression: var(--shiki-token-string);
  --shiki-token-punctuation: oklch(24.78% 0 0);
  --shiki-token-link: var(--shiki-token-string);

  /* from github-light */
  --shiki-color-ansi-black: #24292e;
  --shiki-color-ansi-black-dim: #24292e80;
  --shiki-color-ansi-red: #d73a49;
  --shiki-color-ansi-red-dim: #d73a4980;
  --shiki-color-ansi-green: #28a745;
  --shiki-color-ansi-green-dim: #28a74580;
  --shiki-color-ansi-yellow: #dbab09;
  --shiki-color-ansi-yellow-dim: #dbab0980;
  --shiki-color-ansi-blue: #0366d6;
  --shiki-color-ansi-blue-dim: #0366d680;
  --shiki-color-ansi-magenta: #5a32a3;
  --shiki-color-ansi-magenta-dim: #5a32a380;
  --shiki-color-ansi-cyan: #1b7c83;
  --shiki-color-ansi-cyan-dim: #1b7c8380;
  --shiki-color-ansi-white: #6a737d;
  --shiki-color-ansi-white-dim: #6a737d80;
  --shiki-color-ansi-bright-black: #959da5;
  --shiki-color-ansi-bright-black-dim: #959da580;
  --shiki-color-ansi-bright-red: #cb2431;
  --shiki-color-ansi-bright-red-dim: #cb243180;
  --shiki-color-ansi-bright-green: #22863a;
  --shiki-color-ansi-bright-green-dim: #22863a80;
  --shiki-color-ansi-bright-yellow: #b08800;
  --shiki-color-ansi-bright-yellow-dim: #b0880080;
  --shiki-color-ansi-bright-blue: #005cc5;
  --shiki-color-ansi-bright-blue-dim: #005cc580;
  --shiki-color-ansi-bright-magenta: #5a32a3;
  --shiki-color-ansi-bright-magenta-dim: #5a32a380;
  --shiki-color-ansi-bright-cyan: #3192aa;
  --shiki-color-ansi-bright-cyan-dim: #3192aa80;
  --shiki-color-ansi-bright-white: #d1d5da;
  --shiki-color-ansi-bright-white-dim: #d1d5da80;
}

.dark {
  --shiki-color-text: oklch(86.07% 0 0);
  --shiki-token-constant: oklch(76.85% 0.121 252.34);
  --shiki-token-string: oklch(81.11% 0.124 55.08);
  --shiki-token-comment: oklch(55.18% 0.017 251.27);
  --shiki-token-keyword: oklch(72.14% 0.162 15.49);
  /*--shiki-token-parameter: #ff9800; is same as in light mode */
  --shiki-token-function: oklch(72.67% 0.137 299.15);
  --shiki-token-string-expression: oklch(69.28% 0.179 143.2);
  --shiki-token-punctuation: oklch(79.21% 0 0);
  --shiki-token-link: var(--shiki-token-string);

  /* from github-dark */
  --shiki-color-ansi-black: #586069;
  --shiki-color-ansi-black-dim: #58606980;
  --shiki-color-ansi-red: #ea4a5a;
  --shiki-color-ansi-red-dim: #ea4a5a80;
  --shiki-color-ansi-green: #34d058;
  --shiki-color-ansi-green-dim: #34d05880;
  --shiki-color-ansi-yellow: #ffea7f;
  --shiki-color-ansi-yellow-dim: #ffea7f80;
  --shiki-color-ansi-blue: #2188ff;
  --shiki-color-ansi-blue-dim: #2188ff80;
  --shiki-color-ansi-magenta: #b392f0;
  --shiki-color-ansi-magenta-dim: #b392f080;
  --shiki-color-ansi-cyan: #39c5cf;
  --shiki-color-ansi-cyan-dim: #39c5cf80;
  --shiki-color-ansi-white: #d1d5da;
  --shiki-color-ansi-white-dim: #d1d5da80;
  --shiki-color-ansi-bright-black: #959da5;
  --shiki-color-ansi-bright-black-dim: #959da580;
  --shiki-color-ansi-bright-red: #f97583;
  --shiki-color-ansi-bright-red-dim: #f9758380;
  --shiki-color-ansi-bright-green: #85e89d;
  --shiki-color-ansi-bright-green-dim: #85e89d80;
  --shiki-color-ansi-bright-yellow: #ffea7f;
  --shiki-color-ansi-bright-yellow-dim: #ffea7f80;
  --shiki-color-ansi-bright-blue: #79b8ff;
  --shiki-color-ansi-bright-blue-dim: #79b8ff80;
  --shiki-color-ansi-bright-magenta: #b392f0;
  --shiki-color-ansi-bright-magenta-dim: #b392f080;
  --shiki-color-ansi-bright-cyan: #56d4dd;
  --shiki-color-ansi-bright-cyan-dim: #56d4dd80;
  --shiki-color-ansi-bright-white: #fafbfc;
  --shiki-color-ansi-bright-white-dim: #fafbfc80;
}
